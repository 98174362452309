import dayjs, { ConfigType } from "dayjs";
import "dayjs/locale/ko";
import arraySupport from "dayjs/plugin/arraySupport";

dayjs.locale("ko");
dayjs.extend(arraySupport);

const dayjsCustom = (day?: any) => {
  if (!day) return dayjs();
  if (Array.isArray(day)) {
    const newDay = day.map((d, ind) => {
      if (ind === 1) return d - 1;
      else if (ind === 6) return d / 1000000;
      else return d;
    });

    return dayjs(newDay as ConfigType);
  }
  return dayjs(day);
};

export default dayjsCustom;
