
  import { defineComponent } from "vue";
  import BaseSvg from "./BaseSvg.vue";

  export default defineComponent({
    components: {
      BaseSvg,
    },
    props: {
      width: {
        default: 8,
      },
      height: {
        default: 14,
      },
      fill: {
        default: "#1DBBFF",
      },
      viewBox: {
        default: "0 0 8 14",
      }
    },
  })
