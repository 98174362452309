import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/trade/product_list",
    name: "tradeProductList",
    component: () =>
      import("@/components/pages/trade/product_list/ProductList.vue"),
    meta: {
      layout: "common", 
      kyc: true,
    },
  },
  {
    path: "/trade/product_detail/:marketDealId",
    name: "tradeProductDetail",
    component: () =>
      import("@/components/pages/trade/product_detail/ProductDetailView.vue"),
    meta: {
      layout: "common",
      auth: true,
      kyc: true,
    },
  },
  {
    path: "/trade/apply/:marketDealId",
    name: "tradeBuyView",
    component: () =>
      import("@/components/pages/trade/trade_apply/ApplyIvmtView.vue"),
    meta: {
      layout: "common",
      auth: true,
      kyc: true,
    },
  },

  {
    path: "/trade/sales_apply/:ivmtId",
    name: "applySalesView",
    component: () =>
      import("@/components/pages/trade/sale_apply/ApplyIvmtView.vue"),
    meta: {
      layout: "common",
      auth: true,
      kyc: true,
    },
  },
];

export default routes;
