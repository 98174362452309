import { Module } from 'vuex'
import { RootState } from '../index'
export interface moduleBState {
    count: number;
}

export const moduleB: Module<moduleBState, RootState> = {
    namespaced: true,
    state: () => ({
        count: 0,
    }),
    mutations: {
        SET_OPEN_PRODUCT_CNT: (state, openProductCnt:number) => {
        state.count=openProductCnt;
      },},
    actions: {  
        setOpenProductCnt: ({ commit },  openProductCnt) => {
        commit("SET_OPEN_PRODUCT_CNT", openProductCnt);
      },},
    getters: {},
}