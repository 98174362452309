import { DeleteProductTag, ProductTag } from "@/models/productModels";
import { Module } from "vuex";
import { RootState } from "../index";

export interface TagState {
  product: {
    typeTagList: Array<ProductTag> | [],
    statusTagList: Array<ProductTag> | [],
  }
}

export const tagModule: Module<TagState, RootState> = {
  namespaced: true,
  state: () => ({
    product: {
      typeTagList: [],
      statusTagList: [],
    }
  }),
  mutations: {
    SET_PRODUCT_TAG: (state, { newTypeTagList, newStatusTagList }) => {
      state.product.typeTagList = newTypeTagList;
      state.product.statusTagList = newStatusTagList;
    },
    DELETE_PRODUCT_TAG: (state, {index, listName}: DeleteProductTag) => {
      state.product[listName].splice(index, 1);
    }
  },
  actions: {
    applyProductTag: ({ commit }, { typeTagList, statusTagList }) => {
      const newTypeTagList: Array<ProductTag> = [];
      const newStatusTagList: Array<ProductTag>  = [];
      typeTagList.forEach((tag: ProductTag) => {
        if (tag.selected && tag.value !== "all") newTypeTagList.push(tag);
      })
      statusTagList.forEach((tag: ProductTag) => {
        if (tag.selected && tag.value !== "all") newStatusTagList.push(tag);
      })
      commit("SET_PRODUCT_TAG", { newTypeTagList, newStatusTagList })
    },
    deleteProductTag: ({ commit }, { index, listName }) => {
      commit("DELETE_PRODUCT_TAG", { index, listName});
    }
  },
  getters: {},
};
