import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/mylounge/ivmtdetails",
    name: "ivmtDetails",
    component: () => import("@/components/pages/mylounge/IvmtDetails.vue"),
    meta: {
      layout: "common",
      auth: true,
      kyc: true,
    },
  },
  {
    path: "/mylounge/accountmgmt",
    name: "accountmgmt",
    component: () => import("@/components/pages/mylounge/AccountMgmtView.vue"),
    meta: {
      layout: "common",
      auth: true,
      kyc: true,
    },
  },
  {
    path: "/mylounge/ivmt_status",
    name: "ivmtStatus",
    component: () => import("@/components/pages/mylounge/IvmtStatusView.vue"),
    meta: {
      layout: "common",
      auth: true,
      kyc: true,
    },
  }
];

export default routes;
