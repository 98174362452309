import { getProductIsInvestResponse } from "./../models/response/productResponse";
import {
  ProductListRequest,
  getProductBondNoteShcdRequest,
  getProductCardRequest,
  getProductReceiptRequest,
  getProductExpecSchdRequest,
  getgetProductBondNoteRequest,
  getIvmtReceiptRequest,
} from "@/models/request/productRequest";
import {
  ProductListResponse,
  getProductBondNoteShcdResponse,
  getProductCardResponse,
  getProductReceiptResponse,
  ProductDetailResponse,
  getProductExpectSchdResponse,
  getProductBondNoteResponse,
} from "@/models/response/productResponse";

import apiCaller from "./index";
import { ProductExpectSchd } from "@/models/productModels";

export async function getProductDetailApi(
  productId: string
): Promise<ProductDetailResponse> {
  const response = await apiCaller.get(
    "/products/detail/:productId",
    { params: { productId } },
    {}
  );
  return response.data;
}

export async function getProductListApi(
  request: ProductListRequest
): Promise<ProductListResponse> {
  const response = await apiCaller.get("/products", { query: request }, {});
  return response.data;
}

export async function getProductBondNoteShcdApi(
  productId: getProductBondNoteShcdRequest
): Promise<getProductBondNoteShcdResponse> {
  const response = await apiCaller.get(
    "/products/:productId/bond_note/shcd",
    { params: { productId } },
    {}
  );
  return response.data;
}

export async function getProductBondNoteApi(
  productId: getgetProductBondNoteRequest
): Promise<getProductBondNoteResponse> {
  const response = await apiCaller.get(
    "/users/bond_note/:productId",
    { params: { productId } },
    {}
  );
  return response.data;
}

export async function getProductCardApi(
  productId: getProductCardRequest
): Promise<getProductCardResponse> {
  const response = await apiCaller.get(
    "/products/:productId",
    { params: { productId } },
    {}
  );
  return response.data;
}
export async function getProductReceiptApi(
  productId: getProductReceiptRequest
): Promise<getProductReceiptResponse> {
  const response = await apiCaller.get(
    "/products/:productId/receipt",
    { params: { productId } },
    { responseType: "blob" }
  );
  return response.data;
}

export async function getIvmtReceiptApi(
  ivmtId: getIvmtReceiptRequest
): Promise<getProductReceiptResponse> {
  const response = await apiCaller.get(
    "/ivmts/:ivmtId/receipt",
    { params: { ivmtId } },
    { responseType: "blob" }
  );
  return response.data;
}

export async function getProductExpectSchdApi({
  productId,
  amount,
}: getProductExpecSchdRequest): Promise<getProductExpectSchdResponse> {
  const response = await apiCaller.get(
    "/products/:productId/expected_profit",
    { params: { productId }, query: { amount } },
    {}
  );
  return response.data;
}

export async function getProductFilterApi(request: any) {
  const response = await apiCaller.get(
    "/products/search",
    { query: request },
    {}
  );
  return response.data;
}

export async function getProductIsInvest(
  productId: string
): Promise<getProductIsInvestResponse> {
  const response = await apiCaller.get(
    `/products/${productId}/is_invest`,
    {},
    {}
  );
  return response.data;
}
