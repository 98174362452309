import { getProductIsInvestResponse, MarketDealDetailResponse } from "./../models/response/marketResponse";
import {
  MarketListRequest,
  getMarketCardRequest,
  getMarketExpecSchdRequest,
  ApplyPurchase,
  ApplySale
} from "@/models/request/marketRequest";
import {
  ProductListResponse,
  getProductBondNoteShcdResponse,
  getProductCardResponse,
  getProductReceiptResponse,
  getProductExpectSchdResponse,
  getProductBondNoteResponse,
  MarketListResponse,
  PreForSaleListResponse
} from "@/models/response/marketResponse";

import apiCaller from "./index";
import { PreForSaleItem, ProductExpectSchd } from "@/models/productModels";
import { getProductBondNoteShcdRequest, getProductExpecSchdRequest, ProductListRequest } from "@/models/request/productRequest";
import { rsaEncryptPwd } from "@/script/common/rsaEncryptScript";
import { ApplyIvmtUserInfo } from "@/models/response/userInfoResponse";

export async function getMarketListApi(
  request: MarketListRequest
): Promise<MarketListResponse> {
  const response = await apiCaller.get("/market/list", { query: request }, {});
  return response.data;
}

export async function getPreForSaleListApi(): Promise<PreForSaleListResponse> {
  const response = await apiCaller.get("/market/preForSale", { }, {});
  return response.data;
}
export async function getPreForSaleApi(
  ivmtId: string): Promise<PreForSaleItem> {
  const response = await apiCaller.get("/market/preForSale/:ivmtId",  { params: { ivmtId } }, {});
  return response.data;
}


export async function getProductBondNoteShcdApi(
  productId: getProductBondNoteShcdRequest
): Promise<getProductBondNoteShcdResponse> {
  const response = await apiCaller.get(
    "/market/:productId/bond_note/shcd",
    { params: { productId } },
    {}
  );
  return response.data;
}


export async function getProductExpectSchdApi({
  productId,
  amount,
}: getProductExpecSchdRequest): Promise<getProductExpectSchdResponse> {
  const response = await apiCaller.get(
    "/market/:productId/expected_profit",
    { params: { productId }, query: { amount } },
    {}
  );
  return response.data;
}


export async function submitPurchaseApi({
  marketDealId,
  amount,
  price,
  marketTrstId
}: ApplyPurchase): Promise<ApplyPurchase> {
  amount= await rsaEncryptPwd(""+amount);
  price= await rsaEncryptPwd(""+price);
  const response = await apiCaller.post(
    "/market/marketDeal/:marketDealId",
    {
      params: { marketDealId },
      body: { amount, price ,marketTrstId},
    },
    {}
  );
  return response.data;
}
export async function getMarketTrstIdApi(
  marketDealId:string) {
  const response = await apiCaller.post(
    "/market/marketDeal/trst/:marketDealId",
    {
      params: { marketDealId },
      body:{ },
    },
    {}
  );
  return response.data;
}
export async function clearMarketTrstApi() {
  const response = await apiCaller.post(
    "/market/marketDeal/clear_trst",
    {
      params: { },
      body:{ },
    },
    {}
  );
  return response.data;
}
export async function registerMarketApi({
  amount,
  price,
  ivmtId,
  sellingPartial
}: ApplySale): Promise<ApplySale> {
  amount= await rsaEncryptPwd(""+amount);
  price= await rsaEncryptPwd(""+price);
  const response = await apiCaller.post(
    "/market/registerForSale/:ivmtId",
    {
      params: { ivmtId },
      body: { amount, price ,sellingPartial},
    },
    {}
  );
  return response.data;
}
export async function cancelForSaleApi(
  productId:string ){
  const response = await apiCaller.put(
    "/market/cancelForSale/:productId",
    {
      params: { productId },
    },
    {}
  );
  return response.data;
}
export async function getMarketDealDetailApi(
  marketDealId: string
): Promise<MarketDealDetailResponse> {
  const response = await apiCaller.get(
    "/market/detail/:marketDealId",
    { params: { marketDealId } },
    {}
  );
  return response.data;
}

export async function getApplyPurchaseUserInfoApi(
  marketDealId: string
): Promise<ApplyIvmtUserInfo> {
  const response = await apiCaller.get(
    "/market/marketDeals/:marketDealId/info",
    { params: { marketDealId } },
    {}
  );
  return response.data;
}
