import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/register",
    name: "register",
    component: () => import("@/components/pages/register/UserRegisterView.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: false,
    },
  },
  {
    path: "/nice_auth",
    name: "niceauth",
    component: () => import("@/components/pages/register/NiceAuthView.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/register/second/normal/:step",
    name: "SecondNormalRegister",
    component: () =>
      import(
        "@/components/pages/register/second_register/SecondNormalRegisterView.vue"
      ),
    meta: {
      layout: "mobile-no-footer",
      kycFree: true,
      auth: true,
    },
  },
  {
    path: "/register/child/:step",
    name: "ChildRegister",
    component: () =>
      import(
        "@/components/pages/register/child_register/ChildRegisterView.vue"
      ),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/register/company",
    name: "CompanyRegister",
    component: () =>
      import(
        "@/components/pages/register/company_register/CompanyRegisterView.vue"
      ),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/register/normal/redo/:step",
    name: "NormalKycRedo",
    component: () =>
      import(
        "@/components/pages/register/second_register/SecondNormalRegisterView.vue"
      ),
    meta: {
      layout: "mobile-no-footer",
    },
  },
];

export default routes;
