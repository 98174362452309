
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "@/store";
import HeaderView from "./HeaderView.vue";
import HeaderMobileView from "./HeaderMobileView.vue";
import FooterView from "./FooterView.vue";

export default defineComponent({
  name: "CommonLayout",
  components: {
    HeaderView,
    HeaderMobileView,
    FooterView,
  },
  setup() {
    const store = useStore();
    const changeHeaderSideBar = computed(
      () => store.state.open.mobileSidebar.isOpen
    );
    const changeHeaderUserMenu = computed(
      () => store.state.open.mobileUserMenu.isOpen
    );

    return {
      changeHeaderSideBar,
      changeHeaderUserMenu,
    };
  },
});
