export const convertUnit = (target: number, unit: number) => {
  return target / unit;
};

export const convertToKorean = (target: number, decimal: number) => {
  const EUK = 100000000;
  const CHUNMAN = 10000000;
  const BAEKMAN = 1000000;
  const MAN = 10000;
  let returnStr = "";
  const overEuk = Math.floor(target/EUK);
  const overMan = Math.floor((target - overEuk*EUK)/MAN);
  const underMan = (target - overEuk*EUK - overMan*MAN);
  if(underMan==0)
  {
    returnStr = overEuk>0?overEuk+"억"+
    (overMan==0?"원":" "+overMan.toLocaleString()+"만원"):overMan.toLocaleString()+"만원";
  }
  else{
    returnStr = target.toLocaleString()+"원";
  }
/*  if (target >= EUK) return (target / EUK).toFixed(decimal) + "억";
  else if (target >= CHUNMAN)
    return (target / CHUNMAN).toFixed(decimal) + "천만";
  else if (target >= BAEKMAN)
    return (target / BAEKMAN).toFixed(decimal) + "백만";  
  else return (target / MAN).toFixed(decimal) + "만";*/
  return returnStr;
};
export const convertToKoreanEuk = (target: number, decimal: number) => {
  const EUK = 100000000;
  const MAN = 10000000;
  let returnStr = "";
  const overEuk = Math.floor(target/EUK);
  const overMan = Math.floor((target - overEuk*EUK)/MAN);
  const underMan = (target - overEuk*EUK - overMan*MAN);
  
    returnStr = overEuk+"."+overMan;
  return returnStr;
};
export const converType = (value: any, typeObject: any) => {
  return typeObject[value] ? typeObject[value] : "오류";
};

export const convertToWon = (target: number) => {
  return `${Number(target).toLocaleString()} 원`;
};
export const convertToWonTrunc = (target: number) => {
  return `${(Math.trunc(Number(target)/10000)*10000).toLocaleString()} 원`;
};
export const convertToManWon = (target: number) => {
  return `${(Math.trunc(Number(target)/10000)*10000).toLocaleString()} 원`;
};
export const convertNumDigit = (num: number, digit: number) => {
  const numString = num + "";
  return numString.length >= digit
    ? numString
    : Array.from({ length: digit - numString.length }, () => "0").join("") +
        numString;
};
export const convertToPeople = (target: number) => {
  return `${Number(target).toLocaleString()} 명`;
};

export const convertToPer = (target: number) => {
  return `${Number(target).toLocaleString()}%`;
};