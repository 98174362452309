import { ChartTip } from "./../../models/commonModels";
import { Module } from "vuex";
import { RootState } from "../index";
export interface ChartState {
  cmps: {
    tipList: Array<ChartTip>;
  };
  prps: {
    tipList: Array<ChartTip>;
  };
}

export const chartModule: Module<ChartState, RootState> = {
  namespaced: true,
  state: () => ({
    cmps: {
      tipList: [],
    },
    prps: {
      tipList: [],
    },
  }),
  mutations: {
    SET_TIP_LIST: (
      state,
      { id, tipList }: { id: keyof ChartState; tipList: Array<ChartTip> }
    ) => {
      state[id as keyof ChartState].tipList = tipList;
    },
  },
  actions: {},
  getters: {},
};
