
import { defineComponent } from "vue";
import HeaderView from "./HeaderView.vue";

import HeaderMobileView from "./HeaderMobileView.vue";
import FooterView from "./FooterView.vue";
// import UserRegisterView from "@/components/pages/register/UserRegisterView.vue";

export default defineComponent({
  name: "MobileNoFooter",
  components: {
    HeaderView,

    HeaderMobileView,
    FooterView,
    // UserRegisterView,
  },
});
