import axios from './config';
import { requestUrl } from '@/utils/apiUtils';
import { Payload } from '@/models/utils';

const apiCaller = {
    get: (url: string, payload: Payload, option: any) => axios.get(requestUrl(url, payload), option),
    post: (url: string, payload: Payload, option: any) => axios.post(requestUrl(url, payload), payload.body, option),
    put: (url: string, payload: Payload, option: any) => axios.put(requestUrl(url, payload), payload.body, option),
    delete: (url: string, payload: Payload, option: any) => axios.delete(requestUrl(url, payload), option),
}

export default apiCaller
