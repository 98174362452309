import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import {
  Layout,
  Menu,
  Button,
  Input,
  InputNumber,
  Form,
  Checkbox,
  Modal,
  Carousel,
  DatePicker,
  Select,
  Switch,
  Radio,
  Spin,
  Collapse,
  Tabs,
  Progress,
  Tag
} from "ant-design-vue";

import "ant-design-vue/dist/antd.css";

import "@/assets/scss/site.scss";

//import "./serviceWorker";

import VueCountdown from "@chenfengyuan/vue-countdown";

createApp(App)
  .use(store, key)
  .use(router)
  .use(Layout)
  .use(Menu)
  .use(Button)
  .use(Input)
  .use(InputNumber)
  .use(Form)
  .use(Checkbox)
  .use(Select)
  .use(Modal)
  .use(Carousel)
  .use(Switch)
  .use(Radio)
  .use(Progress)
  .use(DatePicker)
  .component(VueCountdown.name, VueCountdown)
  .use(Spin)
  .use(Collapse)
  .use(Tabs)
  .use(Tag)
  .mount("#app");