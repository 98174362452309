import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/support/notices",
    name: "notifyList",
    component: () => import("@/components/pages/support/NotifyList.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/support/faqs",
    name: "faqList",
    component: () => import("@/components/pages/support/FaqList.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/support/inspections",
    name: "inspectionList",
    component: () => import("@/components/pages/support/InspectionList.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/support/voc",
    name: "epilogueList",
    component: () => import("@/components/pages/support/EpilogueList.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/support/story",
    name: "introduceTrust",
    component: () => import("@/components/pages/support/Introduce.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/support/presses",
    name: "pressList",
    component: () => import("@/components/pages/support/PressList.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/support/events",
    name: "eventList",
    component: () => import("@/components/pages/support/EventList.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/support/content/:type/:supportId",
    name: "supportContent",
    component: () =>
      import("@/components/pages/support/SupportContentView.vue"),
    meta: {
      layout: "common",
    },
  },
];

export default routes;
