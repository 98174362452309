import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/userinfo",
    name: "userinfo",
    component: () => import("@/components/pages/userinfo/UserInfoView.vue"),
    meta: {
      layout: "mobile-no-footer",
      auth: true,
    },
  },
  {
    path: "/userinfo/change_ivtr",
    name: "ChangeIvtrType",
    component: () => import("@/components/pages/userinfo/ChangeIvtrType.vue"),
    meta: {
      layout: "full",
      auth: true,
    },
  },
  {
    path: "/userinfo/register_loan",
    name: "RegisterLoanBsns",
    component: () => import("@/components/pages/userinfo/RegisterLoanBsns.vue"),
    meta: {
      layout: "full",
      auth: true,
    },
  },
  {
    path: "/userinfo/mobilenumber/nice",
    name: "userinfomobilenumbernice",
    component: () =>
      import(
        "@/components/pages/userinfo/userinfo_components/UserInfoMobileNumberNiceView.vue"
      ),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/userInfo/pwdExpired",
    name: "modPwdExpired",
    component: () => import("@/components/pages/userinfo/userPwdExpired.vue"),
    meta: {
      layout: "full",
      loggedIn: false,
    },
  },
];

export default routes;
