import apiCaller from "./index";
import {
  IvmtDetailsRequest,
  IvmtDetailsProductIdRequest,
  RegisterFromAdFinRequest,
} from "@/models/request/myLoungeRequest";
import {
  IvmtDetailsResponse,
  IvmtStatusResponse,
} from "@/models/response/myLoungeResponse";
import { CommonResponse } from "@/models/response/commonResponse";

export async function getUserInfoApi() {
  const response = await apiCaller.get("/users/mypage", {}, {});
  return response.data;
}
export async function getIsLoginInfoApi() {
  const response = await apiCaller.get("/users/login_info", {}, {});
  return response.data;
}

export async function getIvmtDetailsApi(
  request: IvmtDetailsRequest
): Promise<IvmtDetailsResponse> {
  const response = await apiCaller.get("/users/ivmts", { query: request }, {});
  return response.data;
}

export async function getIvmtDetailsIdApi(
  productId: IvmtDetailsProductIdRequest
): Promise<IvmtDetailsResponse> {
  const response = await apiCaller.get(
    "/users/ivmts/:productId",
    { params: { productId } },
    {}
  );
  return response.data;
}

export async function getIvmtStatusApi(): Promise<IvmtStatusResponse> {
  const response = await apiCaller.get("/users/ivmt_status", {}, {});
  return response.data;
}

export async function postAddressChangeApi(request: any) {
  const response = await apiCaller.put(
    "/users/mypage/address",
    { body: request },
    {}
  );
  return response.data;
}

export async function putMobileNumberChangeApi(request: any) {
  const response = await apiCaller.put(
    "/users/mypage/phone",
    { body: request },
    {}
  );
  return response.data;
}
export async function validatePwdApi(request: any) {
  const response = await apiCaller.put(
    "/users/mypage/pwd/validate",
    { body: request },
    {}
  );
  return response;
}

export async function confirmPwdApi(request: any) {
  const response = await apiCaller.put(
    "/users/mypage/pwd/confirm",
    { body: request },
    {}
  );
  return response;
}

export async function putPwdChangeApi(request: any) {
  const response = await apiCaller.put(
    "/users/mypage/pwd",
    { body: request },
    {}
  );
  return response;
}
export async function putPwdChangeDateApi() {
  const response = await apiCaller.put(
    "/users/mypage/pwd/after30",
    {},
    {}
  );
  return response;
}


export async function putIsMarketingAllowApi(request: any, allowType: any) {
  const response = await apiCaller.put(
    "/users/mypage/allow/:allowType",
    { params: { allowType }, body: request },
    {}
  );
  return response;
}

// 투자자 변경 신청 진행 상황
export async function getIvtrTypePrgsApi() {
  const response = await apiCaller.get("/users/ivtr_type/progress", {}, {});
  return response;
}

// 대부업 신청 진행 상황
export async function getLoanPrgsApi() {
  const response = await apiCaller.get("/users/loan/progress", {}, {});
  return response;
}


//회원 탈퇴
export async function putUserWithdrawApi(request: any) {
  const response = await apiCaller.put(
    "/users/withdraw",
    { body: request },
    {}
  );
  return response;
}


export async function registerFromAdFinish(
  request: RegisterFromAdFinRequest
): Promise<CommonResponse> {
  const response = await apiCaller.post(
    "/users/register/ad/finish",
    { body: request },
    {}
  );
  return response.data;
}