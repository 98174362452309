
import { defineComponent } from "vue";
import navBarMenu from "./navBarMenu";
import { useNavbar } from "@/script/layout/navBarScript";

export default defineComponent({
  name: "NavBar",
  setup() {
    const { routeNavbar } = useNavbar();
    return {
      navBarMenu,
      routeNavbar,
    };
  },
});
