import { isNumber } from "./../../utils/typeUtils";
import { BrwrInfo, productProcItem } from "./../../models/productModels";
import { getProductDetailApi } from "@/api/productApis";
import { ProductDetailResponse } from "@/models/response/productResponse";
import { Module } from "vuex";
import { RootState } from "../index";
import { convertToKorean, convertToWon } from "@/utils/unitUtil";
import { lineBreak } from "@/utils/stringUtil";
import dayjs from "@/utils/dayjs";
import {
  IvmtPrgsType,
  IVMT_STRG_TYPE,
  IVMT_TYPE,
} from "@/models/types/enumTypes";
import { typeToName } from "@/utils/typeUtils";
import { Cmte } from "@/models/productModels";
export interface ProductState {
  detail: ProductDetailResponse;
}

const initDetail: ProductState["detail"] = {
  code: "",
  msg: "",
  brwrInfoList: [
    {
      creditScore: "",
      debt: "",
      earn: "",
      loan: "",
      pssn: "",
      snsGrade:"",
      snsVal1:0,
      snsVal2:0,
      snsVal3:0,
      snsVal4:0,
    },
  ],
  checkList: {
    checkBrwr: "",
    checkCompany: "",
    checkCstr: "",
    checkDoc: "",
    checkDvlp: "",
    checkLoan: "",
    checkRefundPlan: "",
    checkRefundRsrc: "",
  },
  cmteListVo: {
    innerCmteList: [],
    outerCmteList: [],
  },
  docList: [],
  exitStrgList: [],
  checkedInfoList: [],
  hlgtList: [],
  isptList: [],
  ivmtOvvwVo: {
    brwrProductList: [],
    itrtRpmt: "MONTHLY",
    ivmtSchdVo: {
      postAmount: 0,
      preAmount: 0,
      thisAmount: 0,
      totalAmount: 0,
    },
    name: "",
    period: 0,
    prcpRpmt: "BULLET",
    prcpUse: "",
    rate: 0,
    strg: null,
    type: null,
  },
  loanProtList: [],
  marketOvvwVo: {
    anls: "",
    lgtd: 0,
    lttd: 0,
    etmt:0
  },
  product: {
    address: "",
    amount: 0,
    closeDate: "",
    endDate: "",
    imageUrl: "",
    isInvest: true,
    isOpen: true,
    ivmtPrgsType: null,
    ivmtStrg: null,
    ivmtType: null,
    name: "",
    number: 0,
    openDate: "",
    productId: "",
    rate: 0,
    requiring: 0,
    period: 0,
    url: "",
    productNum:0,
    loanProtection:"",
    totAmount:0

  },
  projectOvvwVo: {
      address: "",
      addressDetail: "",
      bldgArea: null,
      excsArea: null,
      highestFloor: "",
      lowestFloor: "",
      numberOfBldg: "",
      prcpUse: "",
      siteArea: null,
      summary: "",
      totalFloorArea: null,
      zip: "",
      cpltDate: "",
    },
    riskMgmtList: [],
    warning: "",
    contractBrwrCompInfo:{
      creditScore: '',
      loanLateRecord: '',
      jointGuarantee: '',
      rsrcId: 0,
      path: '',
      name: '',
      asset: 0,
      loan: 0,
      salesAmount: 0,
      salesProfit: 0,
      salesProfitCur: 0,
      salesMonths6M: '',
      salesRecords6M: ''
  },
  contractBondInfo:{
    amount: 0,
    amountReceipted: 0,
    amountNotReceipted:0,
    startYm: '',
    endYm: '',
    bondRpmtName: '',
    bondRpmtAddr: '',
    bondRpmtBsnsNumber: '',
    bondRpmtCreditScore: '',
    asset: 0,
    loan: 0,
    salesAmount:0,
    salesProfit: 0,
    salesProfitCur: 0,
    bondDscr: '',
    bondDscrEtc: '',
    title:'',
    path:''
  },
  extraGuaranteeInfo:{
    dscr:'',
    title:'',
    path:''

  },
  brwrComp:false,
  productProc:{
    dscr:'',
    content:'',
    contentClass:''
  }
};
export const productModule: Module<ProductState, RootState> = {
  namespaced: true,
  state: () => ({
    detail: initDetail,
  }),
  mutations: {
    FETCH_DETAIL: (state, payload) => {
      state.detail = payload;
    },
    INIT_DETAIL: (state) => {
      state.detail = initDetail;
    },
    FETCH_DETAIL_MARKET: (state, payload) => {
      state.detail = payload;
    },
  },
  actions: {
    fetchProductDetail: async ({ commit }, productId: string) => {
      const detail = await getProductDetailApi(productId);
      commit("FETCH_DETAIL", detail);
    },
  },
  getters: {
    productIvmtStatusDetail: (state) => {
      const product = state.detail.product;

      return {
        amount: convertToKorean(product.amount, 2),
        name: lineBreak(product.name, 22),
        openDate: product.openDate,
        period: product.period,
        rate: ["REQUEST", "READY", "REPAYMENT"].includes(
          product.ivmtPrgsType as IvmtPrgsType
        )
          ? Math.floor((product.requiring * 100) / product.amount)
          : 100,
        ivmtPrgsType: product.ivmtPrgsType,
        interestRate: product.rate,
      };
    },
    productSummaryDetail: (state) => {
      const ivmtVo = state.detail.ivmtOvvwVo;
      const ivmtSchdVo = {
        postAmount: ivmtVo.ivmtSchdVo.postAmount.toLocaleString(),
        preAmount: ivmtVo.ivmtSchdVo.preAmount.toLocaleString(),
        thisAmount: ivmtVo.ivmtSchdVo.thisAmount.toLocaleString(),
        totalAmount: ivmtVo.ivmtSchdVo.thisAmount.toLocaleString(),
      };
      return {
        ...ivmtVo,
        period: `${ivmtVo.period} 개월`,
        brwrProductList: ivmtVo.brwrProductList.join(", "),
        strg: typeToName(IVMT_STRG_TYPE, ivmtVo.strg),
        type: typeToName(IVMT_TYPE, ivmtVo.type),
        ivmtSchdVo,
        amount:state.detail.product.amount,
        checkRefundPlan:state.detail.checkList.checkRefundPlan,
        checkRefundRsrc:state.detail.checkList.checkRefundRsrc,
        debt:state.detail.brwrInfoList[0].debt,
        totAmount:state.detail.product.totAmount
      };
    },
    projectSummaryDetail: (state) => {
      const project = state.detail.projectOvvwVo;
      return {
        ...project,
        cpltDate: project!=null&&project.cpltDate!=null?dayjs(project.cpltDate).format("YYYY년 MM월 DD일"):"",
        
      };
    },
    exitStrgDetail: (state) => {
      return state.detail.exitStrgList;
    }, 
    checkedInfoDetail: (state) => {
      return state.detail.checkedInfoList;
    },
    nonFinacialGradeInfo: (state) => {
      return state.detail.brwrInfoList[0];
    },
    productProcDetail: (state) => {
      const productProcList = Array<productProcItem>();
      if(state.detail.productProc){
        for(let i=0;i<state.detail.productProc.content.split(';').length;i++){
          productProcList.push({
            content:state.detail.productProc.content.split(';')[i],
            contentClass:state.detail.productProc.contentClass.split(';')[i]
          })
        }
      }
      return {list:productProcList,...state.detail.productProc};
    },
    loanProtDetail: (state) => {
      const loanProtList = state.detail.loanProtList;
      const productAmount = state.detail.product.amount;
      const guaranteeAmount = state.detail.product.ivmtType=="BOND"
                              ?state.detail.contractBondInfo.amount
                              :state.detail.product.ivmtType=="REAL"||state.detail.product.ivmtType=="ETC"
                                ?state.detail.marketOvvwVo.etmt
                                :state.detail.product.amount;


      let sum = 0;
      loanProtList.forEach((prot) => {
        sum += prot.amount;
      });  
     
      const computedList = loanProtList.map((prot) => {
        return {
          pstn: prot.pstn,
          creditor: prot.creditor,
          amount: prot.amount.toLocaleString(),
          rate: Math.round((prot.amount * 100) / guaranteeAmount),
        };
      });
      const rest = guaranteeAmount - sum;
      
      computedList.push({
        pstn: "rest",
        creditor: "잔여 금액",
        amount: rest.toLocaleString(),
        rate: Math.round((rest * 100) / guaranteeAmount),
      });
      if(state.detail.product.ivmtType=="BOND")
      {  
        computedList.unshift({
        pstn: "tot",
        creditor: "매출채권 금액",
        amount: guaranteeAmount.toLocaleString(),
        rate: 100,
      });
      }
      if(state.detail.product.ivmtType=="REAL")
      {  
        computedList.unshift({
        pstn: "tot",
        creditor: "부동산담보 평가금액",
        amount: guaranteeAmount.toLocaleString(),
        rate: 100,
      });
      }
      if(state.detail.product.ivmtType=="ETC")
        {  
          computedList.unshift({
          pstn: "tot",
          creditor: "담보물 평가금액",
          amount: guaranteeAmount.toLocaleString(),
          rate: 100,
        });
        }
      return computedList;
    },
    brwrInfoDetail: (state) => {
      const convertInfo = (brwr: BrwrInfo) => {
        const numberToWon = (target: number | string) => {
          return isNumber(target) ? convertToWon(Number(target)) : target;
        };
        return {
          creditScore: isNumber(brwr.creditScore)
            ? `${brwr.creditScore} 점`
            : brwr.creditScore,
          debt: numberToWon(brwr.debt),
          earn: numberToWon(brwr.earn),
          loan: numberToWon(brwr.loan),
          pssn: numberToWon(brwr.pssn),
          checkDvlp:state.detail.checkList.checkDvlp,
          checkCstr:state.detail.checkList.checkCstr,
        };
      };

      const brwrList = state.detail.brwrInfoList;
      return brwrList.map((brwr) => convertInfo(brwr));
    },
    isptListDetail: (state) => {
      const isptList = state.detail.isptList;
      const cpltDate = state.detail.projectOvvwVo.cpltDate;
      const returnList = isptList.map((ispt) => {
        return {
          ...ispt,
          isptDate: dayjs(ispt.isptDate).format("YYYY.MM.DD"),
        };
      });
      return {
        isptList: returnList,
        cpltDate: cpltDate?dayjs(cpltDate).format("YYYY.MM.DD"):"",
      };
    },
    brwrCompInfoDetail: (state) => {
      const brwrCompInfo = state.detail.contractBrwrCompInfo;
      return {
        brwrComp:state.detail.brwrComp, ...brwrCompInfo,
      };
    },
    bondDetail: (state) => {
      const bondInfoDetail = state.detail.contractBondInfo;
      return {
        ivmtType:state.detail.product.ivmtType, ...bondInfoDetail,
      };
    },
    cmteListDetail: (state) => {
      const cmteList = state.detail.cmteListVo;
      const convertCmte = (cmte: Cmte) => {
        const career = cmte.career ? cmte.career.split(";") : "";
        return {
          ...cmte,
          career,
        };
      };

      const innerCmteList = cmteList.innerCmteList.length
        ? cmteList.innerCmteList.map(convertCmte)
        : [];
      const outerCmteList = cmteList.outerCmteList.length
        ? cmteList.outerCmteList.map(convertCmte)
        : [];
      return {
        innerCmteList,
        outerCmteList,
      };
    },
    checkListDetail: (state) => {
      const checkList = state.detail.checkList;
      const returnCheck: any = {};
      const toArray = (string: string) => {
        return string.split(";");
      };
      for (const [key, value] of Object.entries(checkList)) {
        returnCheck[key] = toArray(value);
      }
      return returnCheck;
    },
    warningDetail: (state) => {
      return state.detail.warning.split(";");
    },
  },
};
