import { isEmpty } from "lodash";
import { Payload } from "@/models/utils";

export function requestUrl(url: string, payload: Payload): string {
  if (isEmpty(payload)) payload = {};

  let u = url;
  if (!isEmpty(payload.params)) {
    Object.keys(payload.params).forEach((o) => {
      u = u.replace(":" + o, payload.params[o]);
    });
  }

  let q = "";
  if (!isEmpty(payload.query)) {
    q = queryString(payload.query);
  }
  return u + q;
}

export function queryString(queryObject: any): string {
  if (isEmpty(queryObject)) return "";

  let queryString = "?";
  const lastIndex = Object.keys(queryObject).length - 1;
  Object.keys(queryObject).forEach((o, index) => {
    if (queryObject[o]) queryString += o + "=" + queryObject[o];
    if (lastIndex !== index) queryString += "&";
  });
  return queryString;
}

export function converToFormData(request: any) {
  const form = new FormData();
  Object.entries(request).forEach(([key, value]) => {
    if (value instanceof File) form.append(key, value, value.name);
    else form.append(key, value as string | Blob);
  });
  return form;
}
