import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "mobile-only" }
const _hoisted_3 = { class: "desktop-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_layout_header_mobile = _resolveComponent("full-layout-header-mobile")!
  const _component_header_view = _resolveComponent("header-view")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_full_layout_header_mobile)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_header_view)
      ])
    ]),
    _createElementVNode("section", null, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}