const navBarMenu = [
  {
    title: "투자하기",
    route: "ivmtProductList",
  },
  {
    title: "기업정보",
    route: "",
    subMenu: [
      {
        title: "기업소개",
        route: "introducecomany",
      },
      {
        title: "공시자료",
        route: "DisclosureView",
      },
      {
        title: "언론보도",
        route: "pressList",
      }
    ]
  },
  {
    title: "고객라운지",
    route: "",
    subMenu: [
      
      {
        title: "트러스트펀드 스토리",
        route: "introduceTrust",
      },
      {
        title: "VoC",
        route: "epilogueList",
      }
    ]
  },
  {
    title: "이용안내",
    route: "",
    subMenu: [
      {
        title: "공지사항",
        route: "notifyList",
      },
      {
        title: "FAQ",
        route: "faqList",
      }
    ]
  },
  {
    title: "원리금수취권 중고마켓",
    route: "tradeProductList",
  },
  {
    title: "솔루션 소개",
    route: "introducesolution",
  },
];

export default navBarMenu;
