
import { defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import CommonLayout from "./components/layout/CommonLayout.vue";
import FullLayout from "./components/layout/FullLayout.vue";
import BlankLayout from "./components/layout/BlankLayout.vue";
import MobileNoFooter from "./components/layout/MobileNoFooter.vue";
import MobileYesFooterLayout from "./components/layout/MobileYesFooterLayout.vue";
import { useTopButton } from "@/script/common/scrollScript";
import { debounce } from "lodash";




export default defineComponent({
  name: "App",
  components: {
    CommonLayout,
    FullLayout,
    BlankLayout,
    MobileNoFooter,
    MobileYesFooterLayout,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      switch (route.meta.layout) {
        case "common":
          return "common-layout";
        case "full":
          return "full-layout";
        case "blank":
          return "blank-layout";
        case "mobile-no-footer":
          return "mobile-no-footer";
        case "mobile-yes-footer-layout":
          return "mobile-yes-footer-layout";
        default:
          return "blank-layout";
      }
    });
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // resize
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    const { goToTop, triggerUpbtn, isOpen } = useTopButton();
    onMounted(() => {
      document.body.addEventListener("scroll", debounce(triggerUpbtn, 100));
    });
    return {
      goToTop,
      isOpen,
      layout,
    };
  },
});
