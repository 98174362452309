import { reactive, UnwrapRef, ref } from "vue";

export const rsaEncrypt = async (propsData: any) => {
  const encSuccessData: any = ref();
  const jsonData: any = JSON.stringify(propsData);

  // if (jsonData === `{}`) {
  //   jsonData = { email: "asdasjhdvajkshd88@gmail.com" };
  //   jsonData = JSON.stringify(jsonData);
  // }

  function str2ab(str: any) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
  // process.env.VUE_RSA_PUBLIC_KEY;
  // const pemEncodedKey = `-----BEGIN PUBLIC KEY-----
  //   MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDIm3o/a4P6fjPYRY9Nmg77H0Y+u6LABDe9lcOKiNacrLEZCR4a9yjHCvWuo5g46Gr8XFmfhQAJG8HSAQr/34CjPNpyY/95bd/u6S+4oux5F1Hu1M5ZPpercE8avvzOLNbCr0patQw+W3+v7A+Z6H6cRJV8hLUi8N54LRwRIAtirwIDAQAB
  //   -----END PUBLIC KEY-----`;
  const pemEncodedKey = process.env.VUE_APP_RSA_PUBLIC_KEY;

  function importRsaKey(pem: any) {
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";
    const pemContents = pem.substring(
      pemHeader.length,
      pem.length - pemFooter.length
    );
    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(pemContents);
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);

    return window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      {
        name: "RSA-OAEP",
        hash: "SHA-1",
      },
      true,
      ["encrypt"]
    );
  }

  async function encryptMessage(publicKey: any) {
    const data = str2ab(jsonData);
    const ipubkey = await importRsaKey(publicKey);
    return window.crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      ipubkey,
      data
    );
  }
  const encryptData = await encryptMessage(pemEncodedKey);

  const unit8EncData: any = new Uint8Array(encryptData);
  const base64String: any = btoa(String.fromCharCode.apply(null, unit8EncData));
  encSuccessData.value = base64String;
  return {
    encSuccessData,
  };
};

export const rsaEncryptPwd = async (propsData: any) => {
  const jsonData: any = propsData;

  // if (jsonData === `{}`) {
  //   jsonData = { email: "asdasjhdvajkshd88@gmail.com" };
  //   jsonData = JSON.stringify(jsonData);
  // }

  function str2ab(str: any) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  const pemEncodedKey = process.env.VUE_APP_RSA_PUBLIC_KEY;

  function importRsaKey(pem: any) {
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";
    const pemContents = pem.substring(
      pemHeader.length,
      pem.length - pemFooter.length
    );
    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(pemContents);
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);

    return window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      {
        name: "RSA-OAEP",
        hash: "SHA-1",
      },
      true,
      ["encrypt"]
    );
  }

  async function encryptMessage(publicKey: any) {
    const data = str2ab(jsonData);
    const ipubkey = await importRsaKey(publicKey);
    return window.crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      ipubkey,
      data
    );
  }
  const encryptData = await encryptMessage(pemEncodedKey);

  const unit8EncData: any = new Uint8Array(encryptData);
  const base64String: any = btoa(String.fromCharCode.apply(null, unit8EncData));
  return base64String;
};
