
import { defineComponent } from "vue";
import MoreVector from "@/components/svg/MoreVector.vue";
import { useMobileNav } from "@/script/layout/navBarScript";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "NavBarMobile",
  components: {
    MoreVector,
  },
  setup() {
    const { clickMenu, menuList } = useMobileNav();

    const router = useRouter();

    return {
      menuList,
      clickMenu,
      router,
    };
  },
});
