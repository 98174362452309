import { reactive, ref, computed, Prop } from "vue";
import { getHomeDataApi, getHomeExpectEarningApi } from "@/api/homeApi";
import { useStore } from "@/store";
import {
  BannerGoods,
  DsclData,
  HomeProduct,
  ProductBanner,
} from "@/models/homeModels";
import { convertUnit, convertToKorean } from "@/utils/unitUtil";
import { lineBreak } from "@/utils/stringUtil";
import dayjs from "@/utils/dayjs";
import { FAQ_PHONE_NUM,FAQ_EMAIL } from "@/config/constants";
import { SelectProps } from "ant-design-vue";
import { IVMT_TYPE } from "@/models/types/enumTypes";

export const useHomeData = () => {
  const homeData = ref();
  const loading = ref(false);
  const store = useStore();
  const productCnt= ref<number>(0);
  const products= ref<Array<HomeProduct>>([]);
  
  const fetchHomeData = async () => {

    loading.value = true;
    homeData.value = await getHomeDataApi();
    homeData.value.subProductList = homeData.value.productList.slice();
    homeData.value.subProductList = homeData.value.subProductList.splice(1);
    //products.value= homeData.value.productList.slice();
    //homeData.value.productList = [products.value[0],products.value[2],products.value[4]]
    //homeData.value.subProductList = [products.value[1],products.value[3],products.value[5]]
    loading.value = false;
    productCnt.value = homeData.value.dsclData.openProductCnt;
    store.dispatch("mb/setOpenProductCnt",productCnt.value);
  };
  return {
    products,
    homeData,
    fetchHomeData,
    loading,
  };
};

export const useDsclData = (props: any) => {
  const computedDscl = computed(() => {
    const dsclData: DsclData = props.dsclData;
    return dsclData
      ? {
          itrt: dsclData.itrt,
          yield: dsclData.yield,
          amountPer:Math.floor(dsclData.amountPer/10000).toLocaleString(),
          amount: (Math.floor(dsclData.amount/10000000)/10).toLocaleString(),
          reIvmt: dsclData.reIvmt,
          totalIvmtCnt:0,
        }
      : {  itrt: 0,
        yield: 0,
        amountPer:0,
        amount: 0,
        reIvmt: 0, 
        totalIvmtCnt:0,};
  });

  return {
    computedDscl,
  };
};

export const useHomeProduct = (props: any) => {
  const computedProduct = computed(() => {
    const product: HomeProduct = props.product;

    return {
      amount: convertToKorean(product.amount, 2),
      imageUrl: product.imageUrl,
     // name: lineBreak(product.name, 16),
      name: product.name,
      openDate: dayjs(product.openDate).format("YYYY.MM.DD | HH:mm"),
      period: product.period,
      rate: product.rate,
    };
  });
  return {
    computedProduct,
  };
};

export const useHomeExpectEarning = (props: any) => {
  const investAmount = ref(5000000);
  const expectResult = ref(0);
  const product = ref<HomeProduct>(props.productList[0]);
  const amountList = Array.from({ length: 5 }, (v, i: number) => {
    return { label: `${i + 1}00만원`, value: (i + 1) * 1000000 };
  });

  const converted = computed(() => {
    return {
      investAmount: investAmount.value / 10000,
      expectResult: expectResult.value.toLocaleString(),
    };
  });

  const fetchExpectResult = async () => {
    const { amount } = await getHomeExpectEarningApi({
      amount: investAmount.value,
      productId: product.value.productId,
    });
    expectResult.value = amount;
  };

  return {
    investAmount,
    expectResult,
    product,
    amountList,
    converted,
    fetchExpectResult,
  };
};

export const useProductBanner = (props: any) => {
  const titleInfo = computed(() => {
    const product: ProductBanner = props.bannerInfo;
    return {
      imageUrl: product.imageUrl,
      name: lineBreak(product.bannerGoods.name, 16),
    };
  });

  const bannerGoods = computed(() => {
    const bannerGoods: BannerGoods = props.bannerInfo.bannerGoods;
    return {
      amount: convertToKorean(bannerGoods.amount, 2),
      period: bannerGoods.period,
      rate: bannerGoods.rate ? bannerGoods.rate.toFixed(1) : 0,
      progress: (bannerGoods.requiring * 100) / bannerGoods.amount,
    };
  });

  return {
    titleInfo,
    bannerGoods,
  };
};

export const useCallFaq = () => {
  const phoneNum = FAQ_PHONE_NUM;
  const email=FAQ_EMAIL;
  const callFaq = () => {
    document.location.href = `tel:${phoneNum}`;
  };
  const mailFaq= ()=>{
    document.location.href=`mailto:${email}`;
  }
  return {
    callFaq,
    email,
    phoneNum,
    mailFaq
  };
};

export const useProductTypeLabel = ()=>{
  const ivmtTypeOptions: SelectProps["options"] = Object.entries(
    IVMT_TYPE
  ).map((prop) => {
    return {
      value: prop[0],
      label: prop[1].name,
    };
  });
  const getIvmtLabel = (id:string)=>{
      return ivmtTypeOptions?.find( item => item.value == id)?.label;    
  };
  return {
    getIvmtLabel
  }
}

export const useDsclModalCookie = () => {

  const  setCookie = (name:string, value:string, expiredays:number)=>{
    const today = new Date();
    today.setDate(today.getDate() + expiredays);   
    document.cookie = name + '=' + value + '; expires=' + today.toUTCString();
  
  }
    
  const getCookie=(name:string)=> {
    const cookie = document.cookie;
    
    if (document.cookie != "") {
      const cookie_array = cookie.split("; ");
       for ( const index in cookie_array) {
        const cookie_name = cookie_array[index].split("=");
        if (cookie_name[0] ==name) {
          return cookie_name[1];
        }
      }
    }
    return ;
  }
  return {setCookie,getCookie};
}