import apiCaller from "./index";
import {
  HomeResponse,
  HomeExpectEarningResponse,
} from "@/models/response/homeResponse";
import { HomeExpectEarningRequest } from "@/models/request/homeRequest";
import { homeResponseMock } from "@/mock/response/homeResponseMock";

export async function getHomeDataApi(): Promise<HomeResponse> {
  const response = await apiCaller.get("/index", {}, {});
  return response.data;
}

export async function getHomeExpectEarningApi(
  request: HomeExpectEarningRequest
): Promise<HomeExpectEarningResponse> {
  const response = await apiCaller.get(
    "/index/expected",
    { query: request },
    {}
  );
  return response.data;
}
