
import { defineComponent, ref } from "vue";
import MoreVector from "@/components/svg/MoreVector.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "FullLayoutHeaderMobile",
  components: {
    MoreVector,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
});
