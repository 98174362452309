import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "mobile-only" }
const _hoisted_4 = { class: "desktop-only" }
const _hoisted_5 = { class: "footer desktop-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_mobile_view = _resolveComponent("header-mobile-view")!
  const _component_header_view = _resolveComponent("header-view")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_view = _resolveComponent("footer-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_header_mobile_view)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_header_view)
      ])
    ]),
    _createElementVNode("section", null, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("footer", _hoisted_5, [
      _createVNode(_component_footer_view)
    ])
  ]))
}