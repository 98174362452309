
import { defineComponent, onMounted, computed, ref } from "vue";
import NavBarMobile from "./bar/NavBarMobile.vue";
import {
  useMobileSideBar,
  useMobileNav,
  useMobileUserMenu,
} from "@/script/layout/navBarScript";
import { useLogout, useUserLoginInfo } from "@/script/auth/authScript";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

import { notification, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";

export default defineComponent({
  name: "HeaderMobileView",
  components: {
    NavBarMobile,
  },

  setup() {
    const { isOpen, toggleSidebar } = useMobileSideBar();
    const { sidebarRouter } = useMobileNav();
    const router = useRouter();

    const { isOpen: userMenuOpen, toggleUserMenu } = useMobileUserMenu();
    const useIsLogin = computed(() => store.state.auth.isLogin);
    const userName = computed(() => store.state.auth.name);

    const store = useStore();
    const { logout } = useLogout(store);

    const mobileLogout = () => {
      Modal.confirm({
        title: "로그아웃 하시겠습니까?",
        icon: createVNode(ExclamationCircleOutlined),
        cancelText: "취소",
        okText: "로그아웃",
        wrapClassName: "confirm-modal",
        onOk() {
          toggleUserMenu();
          logout();
        },
        // // eslint-disable-next-line @typescript-eslint/no-empty-function
        // onCancel() {},
      });
    };

    return {
      isOpen,
      toggleSidebar,
      sidebarRouter,
      useIsLogin,
      userName,
      userMenuOpen,
      toggleUserMenu,
      router,
      mobileLogout,
    };
  },
});
