import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/find",
    name: "find",
    component: () => import("@/components/pages/finduser/FindUserView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/find/email",
    name: "findemail",
    component: () =>
      import("@/components/pages/finduser/FindUserEmailView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/find/password",
    name: "findpassword",
    component: () =>
      import("@/components/pages/finduser/FindUserPasswordView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/find/email/nice",
    name: "findemailnice",
    component: () =>
      import("@/components/pages/finduser/FindUserEmailNiceView.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/find/password/nice",
    name: "findepasswordnice",
    component: () =>
      import("@/components/pages/finduser/FindUserPasswordNiceView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/find/password/reset",
    name: "findepasswordreset",
    component: () =>
      import("@/components/pages/finduser/FindUserPasswordResetView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
];

export default routes;
