import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/components/pages/home/HomeView.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/mtnc",
    name: "MtncNotice",
    component: () => import("@/components/pages/notice/MtncView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/doc_check",
    name: "DocCheckNotice",
    component: () => import("@/components/pages/notice/DocCheckView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/kyc_required",
    name: "KycRequiredNotice",
    component: () => import("@/components/pages/notice/KYCRequiredView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/kyc_check",
    name: "KycCheckNotice",
    component: () => import("@/components/pages/notice/KycCheckView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/kyc_block",
    name: "KycBlockNotice",
    component: () => import("@/components/pages/notice/KycBlockView.vue"),
    meta: {
      layout: "mobile-no-footer",
    },
  },
  {
    path: "/disclosure",
    name: "DisclosureView",
    component: () => import("@/components/pages/notice/DisclosureView.vue"),
    meta: {
      layout: "common",
    },
  },

  {
    path: "/increaseLimit",
    name: "LimitGuideView",
    component: () => import("@/components/pages/notice/IncreaseLimitGuide.vue"),
    meta: {
      layout: "common",
    },
  },
];

export default routes;
