import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "viewBox", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: _ctx.viewBox,
    fill: _ctx.fill,
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}