import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "nav-bar-mobile" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "menu-title" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "sub-menu-title" }
const _hoisted_6 = {
  style: {"position":"absolute","right":"3%","bottom":"1%"},
  class: "version"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_more_vector = _resolveComponent("more-vector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (menu, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "menu"
      }, [
        _createElementVNode("div", {
          class: "menu-item pointer-hover",
          onClick: ($event: any) => (_ctx.clickMenu(menu, index))
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(menu.title), 1),
          _createElementVNode("div", {
            class: _normalizeClass(menu.isOpen&&menu.title!='고객라운지' ? 'open-vector' : '')
          }, [
            _createVNode(_component_more_vector, { fill: "#000000" })
          ], 2)
        ], 8, _hoisted_2),
        _createVNode(_Transition, { name: "mobile-nav" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.subMenu, (subMenu, subIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: subIndex,
                  class: "sub-menu-item pointer-hover",
                  onClick: ($event: any) => (_ctx.clickMenu(subMenu, index))
                }, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(subMenu.title), 1),
                  _createVNode(_component_more_vector, { fill: "#000000" })
                ], 8, _hoisted_4))
              }), 128))
            ], 512), [
              [_vShow, menu.subMenu && menu.isOpen === true&&menu.title]
            ])
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128)),
    _createElementVNode("span", _hoisted_6, "v" + _toDisplayString(require("@/config").default.version), 1)
  ]))
}