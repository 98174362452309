
import { defineComponent, ref, computed, createVNode } from "vue";
import NavBar from "./bar/NavBar.vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { getUserInfoApi } from "@/api/userInfoApi";
import { useNavbar } from "@/script/layout/navBarScript";
import { useLogout } from "@/script/auth/authScript";
import { notification, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "HeaderView",
  components: {
    NavBar,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const isOpenHeaderUserInfo = ref(true);
    const isOpenUserHeaderInfoFunc = () => {
      isOpenHeaderUserInfo.value = !isOpenHeaderUserInfo.value;
    };
    const useIsLogin = computed(() => store.state.auth.isLogin);
    const { logout } = useLogout(store);

    const headerLogout = () => {
      Modal.confirm({
        title: "로그아웃 하시겠습니까?",
        icon: createVNode(ExclamationCircleOutlined),
        cancelText: "취소",
        okText: "로그아웃",
        wrapClassName: "confirm-modal",
        onOk() {
          menuElKey.value+=1;
          logout();
        },
        // // eslint-disable-next-line @typescript-eslint/no-empty-function
        // onCancel() {},
      });
    };
    const {
      isOpen,
      toggleNav,
      routeNavbar,
      headerElement,
      navElement,
      navBarElement,
      navBarMenu,
    } = useNavbar();

    const userName = computed(() => store.state.auth.name);
    /*
    if(userName.value!=null&&userName.value.indexOf('**')!=-1&&navBarMenu.length==5){
          navBarMenu.push( {
        title: "원리금수취권 중고마켓",
        route: "tradeProductList",
      });
    }*/
    const menuEl = ref();
    const menuElKey = ref(0);
    const isNormal = computed(()=>{ 
      return store.state.auth.ivtrType=='NORMAL'?true:false;
    });
 
    return {
      isOpen,
      headerElement,
      navElement,
      navBarElement,
      navBarMenu,
      userName,
      useIsLogin,
      router,
      toggleNav,
      routeNavbar,
      isOpenHeaderUserInfo,
      isOpenUserHeaderInfoFunc,
      headerLogout,
      menuEl,
      menuElKey,
      isNormal
    };
  },
});
