import { Module } from "vuex";
import { RootState } from "../index";

export interface FindState {
  data: string;
  trstId: string;
  trstSecret: string;
  email: string;
  pwd: string;
}

export const findModule: Module<FindState, RootState> = {
  namespaced: true,
  state: () => ({
    data: "",
    trstId: "",
    trstSecret: "",
    email: "",
    pwd: "",
  }),

  mutations: {
    SET_PWD: (state, info) => {
      state.data = info.data;
      state.trstId = info.trstId;
      state.trstSecret = info.trstSecret;
    },
    SET_EMAIL: (state, info) => {
      state.email = info.email;
    },
    MODIFY_PWD: (state, info) => {
      state.data = info.data;
      state.trstId = info.trstId;
      state.trstSecret = info.trstSecret;
      state.pwd = info.pwd;
    },
  },
  actions: {
    findPwd: async ({ commit }, findForm: FindState) => {
      commit("SET_PWD", findForm);
    },
    findEmail: async ({ commit }, findForm: FindState) => {
      commit("SET_EMAIL", findForm);
    },
    resetPwd: ({ commit }) => {
      commit("SET_PWD", {
        data: "",
        trstId: "",
        trstSecret: "",
      });
    },
    resetEmail: ({ commit }) => {
      commit("SET_EMAIL", {
        email: "",
      });
    },
    modifyPwd: ({ commit }, findForm: FindState) => {
      commit("MODIFY_PWD", findForm);
    },
  },
  getters: {},
};
