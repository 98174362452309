import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "mobile-only" }
const _hoisted_4 = { class: "desktop-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_full_layout_header_mobile = _resolveComponent("full-layout-header-mobile")!
  const _component_header_view = _resolveComponent("header-view")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_view = _resolveComponent("footer-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_full_layout_header_mobile)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_header_view)
      ])
    ]),
    _createElementVNode("section", null, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, { include: "UserRegisterView" }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1024))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("footer", {
      class: _normalizeClass(_ctx.isFooter ? 'footer' : 'display-none')
    }, [
      _createVNode(_component_footer_view)
    ], 2)
  ]))
}