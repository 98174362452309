
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { routeCompanyIvmt } from "@/script/common/routeScript";
import { useCallFaq } from "@/script/home/homeScript";
import {PhoneOutlined,MailOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: "FooterView",
  components:{
    PhoneOutlined,
    MailOutlined
  },
  setup() {
    const router = useRouter();
    const { phoneNum, callFaq,email,mailFaq } = useCallFaq();
    return {
      router,
      routeCompanyIvmt,
      phoneNum,
      callFaq,
      email,
      mailFaq
    };
  },
});
