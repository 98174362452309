import { Module } from "vuex";
import { RootState } from "../index";

export interface OpenState {
  mobileSidebar: {
    isOpen: boolean;
  };
  mobileUserMenu: {
    isOpen: boolean;
  };
  mobileTopBtn: {
    isOpen: boolean;
  };
  navbar: {
    isOpen: boolean;
  };
}

export const openModule: Module<OpenState, RootState> = {
  namespaced: true,
  state: () => ({
    mobileSidebar: {
      isOpen: false,
    },
    mobileUserMenu: {
      isOpen: false,
    },
    mobileTopBtn: {
      isOpen: false,
    },
    navbar: {
      isOpen: false,
    },
  }),
  mutations: {
    TOGGLE_MOBILE_SIDEBAR: (state) => {
      state.mobileSidebar.isOpen = !state.mobileSidebar.isOpen;
      if (state.mobileSidebar.isOpen) {
        state.mobileUserMenu.isOpen = false;
      }
    },
    TOGGLE_MOBILE_USER_MENU: (state) => {
      state.mobileUserMenu.isOpen = !state.mobileUserMenu.isOpen;
      if (state.mobileUserMenu.isOpen) {
        state.mobileSidebar.isOpen = false;
      }
    },
    CONFIG_MOBILE_USER_MENU: (state, openValue) => {
      state.mobileUserMenu.isOpen = openValue;
    },
    CONFIG_MOBILE_SIDEBAR: (state, openValue) => {
      state.mobileSidebar.isOpen = openValue;
    },
    CONFIG_MOBILE_TOP_BTN: (state, openValue) => {
      state.mobileTopBtn.isOpen = openValue;
    },
    CONFIG_NAVBAR: (state, openValue) => {
      state.navbar.isOpen = openValue;
    },
  },
  actions: {},
  getters: {},
};
