import { Module } from "vuex";
import { RootState } from "../index";
import { loginApi, logoutApi } from "@/api/authApis";
import { LoginForm, LoginState, registerForm } from "@/models/authModels";
import { LOGIN_TOKEN } from "@/config/constants";
import { getUserInfoApi } from "@/api/userInfoApi";
import { IvtrType } from "@/models/types/enumTypes";
import router from "@/router";
import { notification, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";
import { rsaEncrypt, rsaEncryptPwd } from "@/script/common/rsaEncryptScript";

export interface AdState {
  code: string;
  status:string;
}

const initAd = (): AdState => {
  return {
    code: "",
    status:""
    
  };
};

export const adModule: Module<AdState, RootState> = {
  namespaced: true,
  state: () => initAd(),

  mutations: {
    SET_CODE: (state, code) => {
      state.code=  code;
      state.status="";
    },
    CLEAR_CODE: (state) => {
      state.code=  ""
      state.status="finish"
    }
  },
  actions: {
    visitFromAd: async ({ commit }, code: string) => {
      commit("SET_CODE", code);
    },
    clearCodeAfterFinish: async ({ commit }) => {
      commit("CLEAR_CODE");
    },
  },
  getters: {},
};
