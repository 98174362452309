import { computed } from "vue";
import { useStore } from "@/store";
import router from "@/router";
import { elementScrollIntoView } from "seamless-scroll-polyfill";

export const useTopButton = () => {
  const store = useStore();

  const goToTop = () => {
    store.commit("open/CONFIG_MOBILE_TOP_BTN", false);
    elementScrollIntoView(document.body, { behavior: "smooth" });
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const triggerUpbtn = () => {
    const scrollTop = document.body.scrollTop;
    const openValue = scrollTop > 600;
    store.commit("open/CONFIG_MOBILE_TOP_BTN", openValue);
  };

  const isOpen = computed(() => {
    return store.state.open.mobileTopBtn.isOpen;
  });

  return {
    goToTop,
    triggerUpbtn,
    isOpen,
  };
};
