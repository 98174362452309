import { CommonResponse } from "@/models/response/commonResponse";
import {
  IdntOcrRequest,
  IdntVerifyRequest,
  RegisterSecondRequest,
  Start1wonAuthRequest,
  Verify1wonAuthRequest,
  RegisterCompanyRequest,
  RedoNormalKycRequest,
  modifyRecommenderRequest,
} from "./../models/request/authRequest";
import apiCaller from "./index";
import { LoginForm } from "@/models/authModels";
import {
  IdntOcrResponse,
  Start1wonAuthResponse,
} from "@/models/response/authResponse";
import { converToFormData } from "@/utils/apiUtils";
import { rsaEncryptPwd } from "@/script/common/rsaEncryptScript";

export async function loginApi(loginForm: LoginForm) {
  const response = await apiCaller.post(
    `/auths/sign_in`,
    { body: loginForm },
    {}
  );
  return response.data;
}
export async function loginWithKakaoApi(): Promise<{ authUri: string }> {
  const response = await apiCaller.get(`/auths/oauths/kakao/uri`, {}, {});
  return response.data;
}

export async function authKaKaoLoginApi(code: string) {
  await apiCaller.post("/auths/oauths/kakao", { body: { code } }, {});
}

export async function logoutApi() {
  const response = await apiCaller.post("/auths/sign_out", {}, {});
  return response.data;
}

export async function idntOcrApi(
  request: IdntOcrRequest
): Promise<IdntOcrResponse> {
  const form = converToFormData(request);

  const response = await apiCaller.post("/auths/idnt/text", { body: form }, {});
  return response.data;
}

export async function verifyOcrApi(
  request: IdntVerifyRequest
): Promise<CommonResponse> {
  const response = await apiCaller.post(
    "/auths/idnt/verify",
    { body: request },
    {}
  );
  return response.data;
}

export async function start1wonAuth(
  request: Start1wonAuthRequest
): Promise<Start1wonAuthResponse> {
  const response = await apiCaller.post("/auths/1won", { body: request }, {});
  return response.data;
}

export async function verify1wonAuth(
  request: Verify1wonAuthRequest
): Promise<CommonResponse> {
  const response = await apiCaller.post(
    "/auths/1won/verify",
    { body: request },
    {}
  );
  return response.data;
}

export async function registerSecondApi(
  request: RegisterSecondRequest
): Promise<CommonResponse> {
  const response = await apiCaller.post(
    "/auths/sign_up/second",
    { body: request },
    {}
  );
  return response.data;
}

export async function regiserCompanyApi(request: RegisterCompanyRequest) {
  const form = new FormData();
  request.fileList.forEach((file) => {
    form.append("fileList", file);
  });
  const encCprtNum = await rsaEncryptPwd(request.cprtNumber);

  form.append("bsnsNumber", request.bsnsNumber);
  form.append("companyName", request.companyName);
  form.append("managerName", request.managerName);
  form.append("cprtNumber", encCprtNum);
  form.append("email", request.email);
  form.append("isAllowMarketing", request.isAllowMarketing);
  form.append("phone", request.phone);
  form.append("pwd", request.pwd);

  const response = await apiCaller.post(
    "/auths/sign_up/company",
    { body: form },
    {}
  );
  return response;
}

export async function redoNormalKyc(request: RegisterSecondRequest) {
  await apiCaller.post("/auths/kyc/redo", { body: request }, {});
}

export async function modifyRecommenderApi(request:modifyRecommenderRequest){

  await apiCaller.post("/auths/sign_up/recommender", { body: request }, {});
}