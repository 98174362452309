
import { defineComponent } from "vue";
import HeaderView from "./HeaderView.vue";
import FullLayoutHeaderMobile from "./FullLayoutHeaderMobile.vue";

export default defineComponent({
  name: "FullLayout",
  components: {
    HeaderView,
    FullLayoutHeaderMobile,
  },
});
