import { notification } from "ant-design-vue";
import { IvmtPrgsType } from "@/models/types/enumTypes";
import { getProductIsInvest } from "@/api/productApis";
import router from "@/router";
export const routeCompanyIvmt = () => {
  /*notification.error({
    message: "준비중입니다",
  });*/
  
};

export const routePreparing = () => {
  notification.error({
    message: "준비중입니다",
  });
};

export const routeProduct = async (
  prgsType: IvmtPrgsType,
  productId: string
) => {
  const res = await getProductIsInvest(productId as string);
  res.isInvest;
  if (["REQUEST", "COMINGSOON"].includes(prgsType)) {
    router.push({
      name: "ivmtProductDetail",
      params: { productId: productId },
    });
  }else if (["CANCEL"].includes(prgsType)) {
    notification.destroy();
    notification.info({
      message: `모집이 취소된 상품입니다.`,
    });
  } else if (res.isInvest) {
    router.push({
      name: "ivmtProductDetail",
      params: { productId: productId },
    });
  } else {
    notification.destroy();
    notification.info({
      message: `모집이 마감된 상품입니다.`,
      description: "해당 상품에 투자해주신 고객님들만 조회가 가능합니다.",
    });
  }
};
