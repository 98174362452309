
import { defineComponent, onMounted, ref, computed } from "vue";
import FullLayoutHeaderMobile from "./FullLayoutHeaderMobile.vue";
import HeaderView from "./HeaderView.vue";

import HeaderMobileView from "./HeaderMobileView.vue";
import FooterView from "./FooterView.vue";
import UserRegisterView from "@/components/pages/register/UserRegisterView.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MobileYesFooterLayout",
  components: {
    FullLayoutHeaderMobile,
    HeaderView,
    FooterView,
  },

  setup() {
    const terms = ref(true);
    const route = useRoute();
    const isFooter = computed(() => {
      if (route.meta && route.meta.footer) return true;
      return false;
    });

    return {
      terms,

      isFooter,
    };
  },
});
