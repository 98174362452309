import router from "@/router";
import { message, notification } from "ant-design-vue";

export const getHttpErrorMsg = (status: number) => {
  switch (status) {
    case 401:
      return { message: "세션이 만료되었습니다. 로그인이 필요합니다" };
    case 413:
      return { message: "제한 용량을 초과하였습니다" };
    case 500:
      return { message: "서버 오류 입니다" };
    default:
      return { message: "알 수 없는 에러가 발생하였습니다" };
  }
};
export const getCodeErrorMsg = (errorData: { code: string; msg: string }) => {
  switch (errorData.code) {
    case "M0529":
      return {
        message:
          "회원가입 시 입력하신 개인정보와 신분증 정보가 일치하지 않습니다.",
      };
    default:
      return { message: errorData.msg };
  }
};

export const getApiError = (error: any) => {
  if (error.response) {
    const errorData = error.response.data;
    if (errorData.msg) return getCodeErrorMsg(errorData).message;
    else return getHttpErrorMsg(error.response.status).message;
  } else {
    console.log(error);
    return "알 수 없는 에러가 발생하였습니다";
  }
};

export const displayApiError = (error: any) => {
  if(error.response.data.code== "M1600"){
    router.push({ name: "KycCheckNotice" });
  }else if(error.response.data.code== "M0542"){
    router.push({ name: "KycBlockNotice" });
  }else if(error.response.data.code=="M1020"){
   notification.info({
      message: "모집이 마감된 상품은 해당 상품에 투자하신 고객님만 확인이 가능합니다."
    });
    setTimeout(() => {
      //history.back();
      router.push({ name: "ivmtProductList" });
    }, 1000)
  }
  else {
    notification.destroy();
    notification.error({
      message: getApiError(error),
    });
  }
};

export const alertApiError = (error: any) => {
  alert(getApiError(error));
};
