import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import { authModule as auth, AuthState } from "./modules/auth.store";
import { openModule as open, OpenState } from "./modules/open.store";
import { tagModule as tag, TagState } from "./modules/tag.store";
import { moduleB as mb, moduleBState } from "./modules/moduleB.store";
import { productModule as prod, ProductState } from "./modules/product.store";
import { marketModule as market, MarketState } from "./modules/marketDeal.store";
import { adModule as ad, AdState } from "./modules/ad.store";
import { findModule as fs, FindState } from "./modules/find.store";
import { registerModule as rg, RegisterState } from "./modules/register.store";
import {
  MyLoungeModule as myLounge,
  MyLoungeState,
} from "./modules/mylounge.store";
import {
  MyLoungeTrstModule as myTrst,
  MyLoungeTrstState,
} from "./modules/myloungetrst.store";
import { chartModule as chart, ChartState } from "./modules/chart.store";
export interface RootState {
  auth: AuthState;
  mb: moduleBState;
  open: OpenState;
  tag: TagState;
  prod: ProductState;
  fs: FindState;
  myTrst: MyLoungeTrstState;
  myLounge: MyLoungeState;
  rg: RegisterState;
  chart: ChartState;
  market:MarketState;
  ad:AdState
}

export const key: InjectionKey<Store<RootState>> = Symbol();

export function useStore() {
  return baseUseStore(key);
}
export const store = createStore<RootState>({
  modules: {
    auth,
    mb,
    open,
    tag,
    prod,
    fs,
    myTrst,
    myLounge,
    rg,
    chart,
    market,
    ad
  },
});
