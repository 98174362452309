import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/pages/login/LoginView.vue"),
    meta: {
      layout: "full",
      loggedIn: false,
    },
  },
  {
    path: "/login/oauth/kakao",
    name: "KaKaoAuth",
    component: () => import("@/components/pages/login/KakaoAuthView.vue"),
    meta: {
      layout: "blank",
    },
  },
];

export default routes;
