import { DeleteProductTag, ProductTag } from "@/models/productModels";
import {
  PeriodTypes,
  PeriodTag,
  DeleteTrstPeriodTag,
} from "@/models/myloungeModels";
import { Module } from "vuex";
import { RootState, store } from "../index";

export interface MyLoungeTrstState {
  trstPeriod: {
    trstPeriodTag: Array<PeriodTypes> | [];
    trstPeriodTypeTag: Array<PeriodTypes> | [];
    trstPeriodSortTag: Array<PeriodTypes> | [];
    all: Array<PeriodTypes> | [];
  };
}

export const MyLoungeTrstModule: Module<MyLoungeTrstState, RootState> = {
  namespaced: true,
  state: () => ({
    trstPeriod: {
      trstPeriodTag: [],
      trstPeriodTypeTag: [],
      trstPeriodSortTag: [],
      all: [],
    },
  }),
  mutations: {
    SET_PRODUCT_TAG: (state, { newTypeTagList, newStatusTagList }) => {
      //   state.product.typeTagList = newTypeTagList;
      //   state.product.statusTagList = newStatusTagList;
    },
    DELETE_PRODUCT_TAG: (state, { index, listName }: DeleteProductTag) => {
      // state.product[listName].splice(index, 1);
    },
    SET_TRST_PERIOD: (
      state,
      { newTrstPeriodListTag, newTrstPeriodTypeTag, newtrstPeriodSortTag }
    ) => {
      state.trstPeriod.trstPeriodTag = newTrstPeriodListTag;
      state.trstPeriod.trstPeriodTypeTag = newTrstPeriodTypeTag;
      state.trstPeriod.trstPeriodSortTag = newtrstPeriodSortTag;
    },
    DELETE_TRST_PERIOD_TAG: (
      state,
      { index, listName }: DeleteTrstPeriodTag
    ) => {
      if (listName === "all") {
        state.trstPeriod.trstPeriodTag = [];
        state.trstPeriod.trstPeriodTypeTag = [];
        state.trstPeriod.trstPeriodSortTag = [];
      }

      state.trstPeriod[listName].splice(index, 1);
    },
  },
  actions: {
    applyProductTag: ({ commit }, { typeTagList, statusTagList }) => {
      const newTypeTagList: Array<ProductTag> = [];
      const newStatusTagList: Array<ProductTag> = [];
      typeTagList.forEach((tag: ProductTag) => {
        if (tag.selected && tag.value !== "all") newTypeTagList.push(tag);
      });
      statusTagList.forEach((tag: ProductTag) => {
        if (tag.selected && tag.value !== "all") newStatusTagList.push(tag);
      });
      commit("SET_PRODUCT_TAG", { newTypeTagList, newStatusTagList });
    },
    deleteProductTag: ({ commit }, { index, listName }) => {
      commit("DELETE_PRODUCT_TAG", { index, listName });
    },
    applyTrstPeriod: (
      { commit },
      { trstPeriodListTag, trstPeriodTypeTag, trstPeriodSortTag }
    ) => {
      //   commit("DELETE_TRST_PERIOD");

      const newTrstPeriodListTag: Array<PeriodTag> = [];
      const newTrstPeriodTypeTag: Array<PeriodTag> = [];
      const newtrstPeriodSortTag: Array<PeriodTag> = [];
      trstPeriodListTag.forEach((tag: PeriodTag) => {
        if (tag.selected && tag.value !== "all") newTrstPeriodListTag.push(tag);
      });
      trstPeriodTypeTag.forEach((tag: PeriodTag) => {
        if (tag.selected && tag.value !== "all") newTrstPeriodTypeTag.push(tag);
      });
      trstPeriodSortTag.forEach((tag: PeriodTag) => {
        if (tag.selected && tag.value !== "all") newtrstPeriodSortTag.push(tag);
      });

      commit("SET_TRST_PERIOD", {
        newTrstPeriodListTag,
        newTrstPeriodTypeTag,
        newtrstPeriodSortTag,
      });
    },
    deleteTrstPeriodTag: ({ commit }, { index, listName }) => {
      commit("DELETE_TRST_PERIOD_TAG", { index, listName });
    },
  },
  getters: {},
};
