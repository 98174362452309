import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/introduce/company",
    name: "introducecomany",
    component: () =>
      import("@/components/pages/introduce/IntroduceCompanyView.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/introduce/solution",
    name: "introducesolution",
    component: () =>
      import("@/components/pages/introduce/IntroduceSolutionView.vue"),
    meta: {
      layout: "common",
    },
  },
];

export default routes;
