
export const lineBreak = (target: string, lineMax: number): string => {
  const wordArray = target.split(" ");
  let result = "";
  let letterALine = 0;
  wordArray.forEach((word, index) => {
    const addingWord = index === 0 || index === target.length - 1 ? word : word + " ";
    letterALine += addingWord.length;
    
    if (letterALine > lineMax) {
      result += "<br>";
      letterALine = 0;
    } 
    result +=" "+addingWord;
  })
  return result;
}