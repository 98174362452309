import { Module } from "vuex";
import { RootState } from "../index";
import { loginApi, logoutApi } from "@/api/authApis";
import { LoginForm, LoginState, registerForm } from "@/models/authModels";
import { LOGIN_TOKEN } from "@/config/constants";
import { getUserInfoApi } from "@/api/userInfoApi";
import { IvtrType } from "@/models/types/enumTypes";
import router from "@/router";
import { notification, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";
import { rsaEncrypt, rsaEncryptPwd } from "@/script/common/rsaEncryptScript";

export interface AuthState {
  name: string;
  ivtrType: IvtrType;
  isKyc: boolean;
  isJunior:boolean;
  isCdd: boolean;
  isLogin: LoginState;
  status: any;
  checkCompany: string;
  pwdExpired:boolean;
}

const initAuth = (): AuthState => {
  return {
    name: "",
    ivtrType: "NONINVEST",
    isKyc: false,
    isLogin: false,
    isJunior: false,
    status: "",
    checkCompany: "",
    isCdd: false,
    pwdExpired: false,
  };
};

export const authModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: () => initAuth(),

  mutations: {
    SET_USER: (state, info) => {
      state.name = info.name;
      state.isKyc = info.isKyc;
      state.ivtrType = info.ivtrType;
      state.isJunior=info.isJunior;
      state.isCdd = info.isCdd;
      state.isLogin = info.loginState;
    },
    LOGIN_USER: (state, info) => {
      state.name = info.name;
      state.isLogin = info.isLogin;
      state.pwdExpired=info.pwdExpired;
    },
    SET_LOGGED_IN: (state, info) => {
      state.name = info.name;
      state.isKyc = info.isKyc;
      state.ivtrType = info.ivtrType;
      state.isJunior = info.isJunior;
      state.isCdd = info.isCdd;
      state.isLogin = info.isLogin;
    },
    SET_LOGIN_STATE: (state, loginState) => {
      state.isLogin = loginState;
    },
    SET_COMPANY: (state, ivtrType) => {
      state.checkCompany = ivtrType.type;
      state.status = ivtrType.status;
    },
    MOD_PWD_EXPIRED:(state,info)=>{
      state.pwdExpired = info.pwdExpired;
    },
  },
  actions: {
    login: async ({ commit }, loginForm: LoginForm) => {
      const encLoginForm = {
        email: loginForm.email,
        pwd: await rsaEncryptPwd(loginForm.pwd),
      };
      const loginResponse = await loginApi(encLoginForm);
      commit("LOGIN_USER", loginResponse);
    },
    logout: async ({ commit }) => {
      await logoutApi();
      notification.success({
        message: "로그아웃 되었습니다.",
      });
      commit("SET_LOGGED_IN", initAuth());
    },
    loggedIn: async ({ commit }, isLogin: string) => {
      commit("SET_LOGGED_IN", isLogin);
    },
    checkCompany: async ({ commit }, ivtrType: any) => {
      commit("SET_COMPANY", ivtrType.value.data);
    },

    withdraw: async ({ commit }) => {
      await logoutApi();
      notification.success({
        message: "탈퇴처리 되었습니다.",
      });
      commit("SET_LOGGED_IN", initAuth());
    }
  },
  getters: {},
};
