export const DISPLAY_STATUS = {
  DORMANT: { name: "휴먼 회원" },
  DELETED: { name: "탈퇴 회원" },
  BLOCKED: { name: "비정상 회원" },
  ACTIVE: { name: "활동 회원" },
};
export type DisplayStatus = keyof typeof DISPLAY_STATUS;

export const IVTR_TYPE = {
  NONINVEST: { name: "1차가입회원" },
  NORMAL: { name: "일반투자자" },
  INCOME: { name: "소득적격투자자" },
  EXPERT: { name: "전문개인투자자" },
  COMPANY: { name: "법인투자자" },
  FINANCIAL: { name: "여신금융기관" },
};
export type IvtrType = keyof typeof IVTR_TYPE;

export const IVMT_TYPE = {
  PF: { name: "부동산PF" },
  REAL: { name: "부동산담보" },
  BOND: {name:"매출채권담보"},
  ETC:{name:"기타담보"},
  P_CREDIT: { name: "개인신용" },
  C_CREDIT: { name: "법인신용" },
};
export type IvmtType = keyof typeof IVMT_TYPE;

export const IVMT_PRGS_TYPE = {
  COMINGSOON: { name: "모집예정" },
  REQUEST: { name: "모집중" },
  READY: { name: "모집완료" },
  REPAYMENT: { name: "상환중" },
  OVERDUE: { name: "연체" },
  LONGOVERDUE: { name: "장기연체" },
  FINISH: { name: "상환완료" },
  CANCEL: { name: "취소" },
  INSOLVENT: { name: "부실" },
  DELAY: { name: "연기" },
};
export type IvmtPrgsType = keyof typeof IVMT_PRGS_TYPE;

export const IVMT_STRG_TYPE = {
  CORE: { name: "핵심자산투자" },
  VALUEADD: { name: "가치부가투자" },
  OPPORTUNISTIC: { name: "기회투자" },
};
export type IvmtStrgType = keyof typeof IVMT_STRG_TYPE;

export const NTFC_TYPE = {
  NOTIFY: { name: "공지사항" },
  FAQ: { name: "FAQ" },
  INSPECTION: { name: "현장실사" },
  EPILOGUE: { name: "투자후기" },
  PRESS: { name: "언론보도" },
};
export type NtfcType = keyof typeof NTFC_TYPE;

export const CMTE_TYPE = {
  IN: { name: "내부 심의위원" },
  OUT: { name: "외부 심의위원" },
};
export type CmteType = keyof typeof CMTE_TYPE;

export const RPMT_METHOD = {
  BULLET: { name: "만기 일시 상환" },
  MONTHLY: { name: "매월 말일 상환" },
};
export type RpmtMethod = keyof typeof RPMT_METHOD;


export const MARKET_DEAL_PRGS_TYPE = {
  SELLING: { name: "판매중" },
  DEAL_FINISHED: { name: "거래완료" },
  FINISH: { name: "판매취소" },

};
export type MarketDealPrgsType = keyof typeof MARKET_DEAL_PRGS_TYPE;


export const RPMT_TYPE = {
  INTEREST: { name: "이자 상환" },
  MIDTERM: { name: "중도 상환" },
  COMPLETE: { name: "상환 완료" },
  OVERDUE: { name: "연체 상환" },
  PARTIAL: { name: "일부 상환" },
  DELAYED: { name: "상환 지연중" },
  EXPECTED: { name: "상환 예정" },
  SELL: { name: "판매 발생" },
  SOLDOUT: { name: "판매 완료" },
  BUY: { name: "구매 발생" },
};
export type RpmtType = keyof typeof RPMT_TYPE;

export const TRST_TYPE = {
  PUT: { name: "상환" },
};
export type trstType = keyof typeof TRST_TYPE;

export const MEDIA_TYPE = {
  IMAGE: { name: "사진" },
  VIDEO: { name: "동영상" },
  FILE: { name: "문서" },
};
export type MediaType = keyof typeof MEDIA_TYPE;

export const OCR_TYPE = {
  ID_CARD: { name: "주민등록증" },
  DRIVER_LICENSE: { name: "운전면허증" },
  PASSPORT: { name: "여권" },
};
export type OcrType = keyof typeof OCR_TYPE;

export const BANNER_TYPE = {
  IDEX: { name: "기본 배너" },
  EVENT: { name: "이벤트 배너" },
  PRODUCT: { name: "상품 배너" },
};
export type BannerType = keyof typeof BANNER_TYPE;


export const USER_WITHDRAW_REASON_TYPE = {
  NOTUSE: { name: "자주 사용하지 않아요." },
  NOPRODUCT: { name: "원하는 상품이 없어요." },
  NOTEASY: { name: "서비스 이용이 어려워요." },
  INFONOTSAFE: { name: "개인정보 유출이 걱정되요." },
  NOTSERVICE: { name: "고객서비스 불만이에요" },
  ETC: { name: "기타 (직접 입력)" },
};
export type UserWithdrawReasonType = keyof typeof USER_WITHDRAW_REASON_TYPE;
