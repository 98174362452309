import axios from "axios";
import { LOGIN_TOKEN } from "@/config/constants";
import router from "@/router";
import { displayApiError, getHttpErrorMsg } from "@/script/error/errorScript";
import dayjs from "@/utils/dayjs";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api/v1",
  headers: {
    "content-type": "application/json;charset=UTF-8",
    //"x-request-id":dayjs(new Date()).format("YYYYMMDDHHmmssSSS")+Math.floor(Math.random()*10000)
    // accept: "application/json,",
  },
});

instance.interceptors.request.use((config) => {
  if (localStorage.getItem(LOGIN_TOKEN)) {
    config.headers!.authorization = `${localStorage.getItem(LOGIN_TOKEN)}`;
    
  }
  config.headers!["x-request-id"] =`${dayjs(new Date()).format("YYYYMMDDHHmmssSSS")+Math.floor(Math.random()*10000)}`;
  return config;
});
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response.data.code);
    if (error.response.status === 401) {
      alert(getHttpErrorMsg(401).message);
      router.push({ name: "login" });
    } else if (error.response.status === 502) {
      alert("서비스 점검중입니다. 잠시후 다시 시도해주세요.");
    }  else displayApiError(error);
    throw error;
  }
);

export default instance;
