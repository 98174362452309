import { DeleteProductTag, ProductTag } from "@/models/productModels";
import { PeriodTypes, PeriodTag } from "@/models/myloungeModels";
import { Module } from "vuex";
import { RootState } from "../index";

export interface MyLoungeState {
  period: {
    periodList: Array<PeriodTypes> | [];
  };
}

export const MyLoungeModule: Module<MyLoungeState, RootState> = {
  namespaced: true,
  state: () => ({
    period: {
      periodList: [],
    },
  }),
  mutations: {
    SET_PRODUCT_TAG: (state, { newTypeTagList, newStatusTagList }) => {
      //   state.product.typeTagList = newTypeTagList;
      //   state.product.statusTagList = newStatusTagList;
    },
    DELETE_PRODUCT_TAG: (state, { index, listName }: DeleteProductTag) => {
      //   state.product[listName].splice(index, 1);
    },
    SET_PERIOD: (state, { newIvmtPeriodListTag }) => {
      state.period.periodList = newIvmtPeriodListTag;
    },
    DELETE_PERIOD: (state) => {
      state.period.periodList = [];
    },
  },
  actions: {
    applyProductTag: ({ commit }, { typeTagList, statusTagList }) => {
      const newTypeTagList: Array<ProductTag> = [];
      const newStatusTagList: Array<ProductTag> = [];
      typeTagList.forEach((tag: ProductTag) => {
        if (tag.selected && tag.value !== "all") newTypeTagList.push(tag);
      });
      statusTagList.forEach((tag: ProductTag) => {
        if (tag.selected && tag.value !== "all") newStatusTagList.push(tag);
      });
      commit("SET_PRODUCT_TAG", { newTypeTagList, newStatusTagList });
    },
    deleteProductTag: ({ commit }, { index, listName }) => {
      commit("DELETE_PRODUCT_TAG", { index, listName });
    },
    applyPeriodTag: ({ commit }, { periodList }) => {
      const newIvmtPeriodListTag: Array<PeriodTag> = [];
      periodList.forEach((tag: PeriodTag) => {
        if (tag.selected && tag.value !== "all") newIvmtPeriodListTag.push(tag);
      });

      commit("SET_PERIOD", { newIvmtPeriodListTag });
    },

    deletePeriodTag: ({ commit }, { index, listName }) => {
      commit("DELETE_PERIOD", { index, listName });
    },
  },
  getters: {},
};
