import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/ivmt/product_list",
    name: "ivmtProductList",
    component: () =>
      import("@/components/pages/ivmt/product_list/ProductList.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/ivmt/product_detail/:productId",
    name: "ivmtProductDetail",
    component: () =>
      import("@/components/pages/ivmt/product_detail/ProductDetailView.vue"),
    meta: {
      layout: "common",
    },
  },
  {
    path: "/ivmt/apply/:productId",
    name: "applyIvmtView",
    component: () =>
      import("@/components/pages/ivmt/ivmt_apply/ApplyIvmtView.vue"),
    meta: {
      layout: "common",
      auth: true,
      kyc: true,
    },
  },
];

export default routes;
