import { ref, reactive, UnwrapRef, computed } from "vue";
import { LoginForm } from "@/models/authModels";
import { Store } from "vuex";
import { RootState } from "@/store";
import router from "@/router/index";
import { store } from "@/store";
import { getIsLoginInfoApi, getIvtrTypePrgsApi } from "@/api/userInfoApi";

import { notification } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { loginWithKakaoApi } from "@/api/authApis";

export const useLogin = (store: Store<RootState>) => {
  const route = useRoute();
  const loginForm: UnwrapRef<LoginForm> = reactive({
    email: "",
    pwd: "",
  });
  const errorMsgFeildEmail = async (_rule: any) => {
    if (errMsg.value) {
      return Promise.reject();
    }
  };
  const errorMsgFeild = async (_rule: any) => {
    if (errMsg.value) {
      return Promise.reject(`${errMsg.value}`);
    }
  };
  const formRef = ref();
  const loading = ref(false);
  const errMsg = ref("");
  const rules = reactive({
    email: [
      { validator: errorMsgFeildEmail },
      { required: true, message: "이메일을 입력해주세요." },
      { type: "email", message: "이메일 형식에 맞지 않습니다." },
      {
        type: "string",
        max: 50,
        message: "50자 이하를 입력해주세요.",
      },
    ],
    pwd: [
      { validator: errorMsgFeild },
      { required: true, message: "비밀번호를 입력해주세요." },
    ],
  });

  const login = async () => {
    try {
      loading.value = true;
      if (loginForm.email === "" || loginForm.pwd === "") {
        return;
      }
      await store.dispatch("auth/login", loginForm);
      const forward = route.query.target;
      const adCode=  route.query.click;

      if (store.state.auth.pwdExpired) {
        if (forward) router.push(`/userInfo/pwdExpired?target=${forward}`);
        else router.push({ name: "modPwdExpired" });
      } else {
        if (forward) router.push((forward as string)+(adCode?`?click=${adCode}`:``));
        else router.push({ name: "home" });
      }
    } catch (e: any) {
      if (e.response) {
        errMsg.value = e.response.data.msg;
        formRef.value.validate();
        errMsg.value = "";
      }
    } finally {
      loading.value = false;
    }
  };
  return {
    loading,
    loginForm,
    login,
    rules,
    formRef,
    errMsg,
  };
};

export const useLogout = (store: Store<RootState>) => {
  const logout = async () => {
    await store.dispatch("auth/logout");
    router.push({ name: "home" });
  };
  return {
    logout,
  };
};
export const useWithdraw = (store: Store<RootState>) => {
  const logout = async () => {
    await store.dispatch("auth/withdraw");
    router.push({ name: "home" });
  };
  return {
    logout,
  };
};

export const useKakaoLogin = () => {
  const router = useRouter();
  const kakaoResult = ref();
  const onAfterClose = () => {
    const result = JSON.parse(
      document.querySelector<HTMLInputElement>("#kakao-result")!.value
    );
    switch (result.code) {
      case "M0000":
        router.push({ name: "home" });
        break;
      case "M0510":
        notification.error({
          message: "트러스트펀드 회원가입 이후 카카오로 로그인이 가능합니다",
        });
        router.push({ name: "register" });
        break;
      default:
        notification.error({
          message: "로그인 중 오류가 발생했습니다",
        });
    }
  };
  const loginWithKakao = async () => {
    const windowX = document.body.offsetWidth;
    const windowY = document.body.offsetHeight;
    const popWidth = 600;
    const popHeight = 800;
    const { authUri } = await loginWithKakaoApi();
    console.log(authUri);
    window.open(
      authUri,
      "",
      `width=${popWidth},height=${popHeight},top=${
        windowY / 2 - popHeight / 2
      }, left=${windowX / 2 - popWidth / 2}`
    );
  };
  return {
    loginWithKakao,
    onAfterClose,
    kakaoResult,
  };
};

export const useUserLoginInfo = async (to: any) => {
  const userName = computed(() => store.state.auth.name);
  const myDataResponse = await getIsLoginInfoApi();

  store.dispatch("auth/loggedIn", myDataResponse);

  return {
    userName,
    myDataResponse,
  };
};

export const checkIsKyc = async (routePath: string) => {
  const ivtrTypeData = ref();

  const changeIvtr = async () => {
    const res = await getIvtrTypePrgsApi();
    ivtrTypeData.value = res;
    store.dispatch("auth/checkCompany", ivtrTypeData);
  };
  await changeIvtr();

  const ivtrTypeVData = computed(() => store.state.auth.checkCompany);
  const ivtrStatus = computed(() => store.state.auth.status);
  if (store.state.auth.ivtrType === "NONINVEST") {
    if (ivtrTypeVData.value === "COMPANY" && ivtrStatus.value === "PENDING") {
      router.push({ name: "DocCheckNotice" });
    } else {
      notification.error({
        message: "추가 인증 진행 후 이용가능합니다",
        description: "인증 절차를 진행해 주세요",
      });
      router.push({ name: "SecondNormalRegister", params: { step: 1 } });
    }
  } else if (!store.state.auth.isKyc && store.state.auth.isLogin === true) {
    if (ivtrTypeVData.value === "COMPANY" && ivtrStatus.value === "PENDING") {
      router.push({ name: "DocCheckNotice" });
    } else {
      if(store.state.auth.ivtrType=='COMPANY'||store.state.auth.isJunior){
        notification.warn({
          message: "고객알기제도(KYC) 재이행 주기가 도래했습니다",
        });
        router.push({ name: "KycRequiredNotice", params: {  } });
      }
      else{
        notification.warn({
          message: "고객알기제도(KYC) 재이행 주기가 도래했습니다",
          description: "인증 절차를 진행해 주세요",
        });
        router.push({ name: "NormalKycRedo", params: { step: 1 } });
        store.commit("rg/SET_TARGET_ROUTE", routePath);
      }
    }
  }
};
